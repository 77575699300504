<template>
  <v-dialog
    v-model="dialogModal"
    max-width="500px"
    :persistent="true"
  >
    <v-card>
      <v-card-title>
        <span class="headline">New Department</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="title"
                label="Title"
                name="Title"
                v-validate="'required'"
              ></v-text-field>
              <span style="color: red;"
                v-show="errors.has('Title')" v-if="valid">{{ errors.first('Title') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                @click="fetchSelectedFacults"
                v-model="selected"
                label="Choose faculty"
                name="Faculty"
                v-validate="'required'"
                :items="faculty"
              ></v-select>
              <span style="color: red;"
                v-show="errors.has('Faculty')" v-if="valid">{{ errors.first('Faculty') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="code"
                label="Code"
                name="Code"
                v-validate="'required'"
              ></v-text-field>
              <span style="color: red;" v-show="errors.has('Code')" v-if="valid">{{ errors.first('Code') }}</span>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="validate"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewDepartment',
  props: {
    dialogModal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      code: '',
      title: '',
      selected: null,
      faculty: [],
      logger: [],
      header: '',
      valid: true,
    }
  },
  methods: {
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save()
        } else {
          // form have errors
        }
      })
    },
    async fetchSelectedFacults () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_faculty',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.faculty = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    close () {
      this.title = ''
      this.code = ''
      this.selected = ''
      this.valid = false
      this.$emit('offModal', false)
    },
    async save () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      if (this.title === '' || this.code === '' || this.selected === '') {
        this.validate()
      } else {
        const data = {
          title: this.title,
          code: this.code,
          faculty_id: this.selected,
        }
        try {
          const res = await this.axios({
            url: 'manipulate_department',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.$emit('getResponse', res.data)
            this.title = ''
            this.code = ''
            this.selected = ''
            this.valid = false
            this.close()
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
            this.title = ''
            this.code = ''
            this.selected = ''
            this.valid = false
          } else {
            console.log(e)
          }
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
